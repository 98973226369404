import get from 'lodash/get';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { isFalsyString, sentenceCase } from '@emobg/web-utils';
import fleetRouter from '@domains/Carsharing/router/FleetRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { ConnectivityStatusComponent, PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  active: COLORS.success,
  inactive: GRAYSCALE.ground,
};

export const contentCells = [
  {
    title: 'License plate',
    attributeName: 'license_plate',
    displayPriority: 1,
    type: RECORD_TYPES.component,
    minWidth: 120,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: !isFalsyString(result.uuid) ? {
        name: fleetRouter.vehicles.detail.setup,
        params: {
          vehicleUuid: result.uuid,
        },
      } : null,
      linkPermissions: [CARSHARING_PERMISSIONS.viewCsVehicleSetup],
      text: result.license_plate,
    }),
  },
  {
    title: 'Model',
    attributeName: '',
    transformResult: result => `${result.vehicle_brand} - ${result.vehicle_model}`,
    displayPriority: 1,
    minWidth: 150,
  },
  {
    title: 'Category',
    attributeName: 'vehicle_category',
    displayPriority: 1,
    minWidth: 120,
  },
  {
    title: 'Type',
    attributeName: 'model.vehicle_type',
    displayPriority: 1,
    transformValue: sentenceCase,
    minWidth: 90,
  },
  {
    title: 'City',
    attributeName: 'city',
    displayPriority: 1,
    minWidth: 120,
  },
  {
    title: 'Location',
    attributeName: 'location',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 2,
    minWidth: 100,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: badgeColor[status],
    }),
  },
  {
    title: 'Connectivity',
    type: RECORD_TYPES.component,
    displayPriority: 2,
    minWidth: 165,
    component: ConnectivityStatusComponent,
    props: vehicle => ({
      showIcon: false,
      status: get(vehicle, 'telemetry.connectivity_status'),
      isNonConnected: !!get(vehicle, 'non_connected'),
    }),
  },
  {
    title: 'Picture',
    attributeName: 'vehicle_model_picture',
    type: RECORD_TYPES.image,
    columnRatio: 2,
    minWidth: 150,
  },
  {
    title: 'Transmission',
    attributeName: 'transmission',
    columnRatio: 2,
    transformValue: sentenceCase,
    minWidth: 150,
  },
  {
    title: 'Fuel type',
    attributeName: 'vehicle_fuel_type',
    columnRatio: 2,
    transformValue: sentenceCase,
    minWidth: 150,
  },
  {
    title: 'Seats',
    attributeName: 'seats',
    columnRatio: 2,
    minWidth: 150,
  },
  {
    title: 'Operator',
    attributeName: 'dedicated_cs_operator',
    columnRatio: 2,
    minWidth: 150,
  },
];

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'dedicated_cs_operator',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Location',
      attributeName: 'location',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Connectivity',
      attributeName: 'telemetry.connectivity_status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Category',
      attributeName: 'vehicle_category',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Brand',
      attributeName: 'vehicle_brand',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Model',
      attributeName: 'vehicle_model',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'model.vehicle_type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fuel type',
      attributeName: 'vehicle_fuel_type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Transmission',
      attributeName: 'transmission',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Seats',
      attributeName: 'seats',
    },
  },
];
